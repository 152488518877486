function tourneesController($translate, $state, $scope, WS, $window, $sce) {
    'ngInject';
    const vm = this;
 
    vm.filter=  {};

    vm.days = [
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
        'Dimanche'
    ];
    
    vm.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
    };


    vm.getRouteWithDepot = function () {
        WS.get('routing/getRouteWithDepot')
            .then(function (response) {
                vm.routeswithdepot = response.data;
                $scope.$apply();
                /*$('.table_routes').DataTable({
                        "language": DatatableLang
                    });*/
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.findTournees = function () {
         
        console.log(vm.filter)
        WS.post('tournees/findTournees', vm.filter).then(
            function (response) {
 
                if(vm.filter.commercial_codes.length>0){
                    vm.list_users = vm.selectusers.filter(user => vm.filter.commercial_codes.includes(user.code))
                }else{
                    vm.list_users = vm.selectusers;
                }
                vm.all_days = response.data.all_days;

                console.log(vm.all_days, vm.list_users)


                /**
                 * start traitement trie date
                 */
                if(vm.filter.trie=="date"){
                    var trs = ``;
                    _.each(vm.all_days, (day, indexDay) => {
                        _.each(vm.list_users, (user, indexUser) => {
                            trs += `<tr>
                                <td class="text-center">${day.date}</td>
                                <td class="text-center">${day.code_jour}</td>
                                <td class="text-center"></td>
                                <td class="text-center">${user.user}</td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                            </tr>`;
                        })
                    })
                }


                /**
                 * start traitement trie commercial
                 */
                if(vm.filter.trie=="commercial"){
                    var trs = ``;
                    _.each(vm.list_users, (user, indexUser) => {
                        _.each(vm.all_days, (day, indexDay)  => {
                            trs += `<tr>
                            <td class="text-center">${day.date}</td>
                            <td class="text-center">${day.code_jour}</td>
                            <td class="text-center"></td>
                            <td class="text-center">${user.user}</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>`;
                        })
                    })
                }


                vm.tbodys = vm.trustHtml(vm.trs);

                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    }

}
export default {
    name: 'tourneesController',
    fn: tourneesController
};